<template>
  <a-modal
    title="新建合同模板"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleConfirm"
    @cancel="() => { $emit('cancel') }"
  >
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="模版名称">
        <a-input v-model="form.name"></a-input>
      </a-form-model-item>
      <a-form-model-item label="关键词占位符">
        <a-button style="margin-left: 15px" @click="insert(item.en_name)" v-for="item in $Dictionaries.rent_contract" :key="item.name">{{ item.name }}</a-button>
      </a-form-model-item>
      <a-form-model-item label="模版内容">
        <a-spin :spinning="loading">
          <quill-editor
            v-model="form.content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)">
          </quill-editor>
        </a-spin>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

// 表单字段
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    prefixCls: {
      type: String,
      default: 'ant-editor-quill'
    },
    // 表单校验用字段
    // eslint-disable-next-line
    value: {
      type: String
    }
  },
  components: {
    quillEditor
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      form: {
        name: '',
        content: ''
      },
      dictionaries: this.$Dictionaries,
      content: '',
      name: '',
      editorOption: {
        // some quill options
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
  },
  methods: {
    insert (value) {
      const quill = this.$refs.myQuillEditor.quill
      const length = this.$refs.myQuillEditor.quill.selection.savedRange.index
      const data = '{' + value + '}'
      quill.insertEmbed(length, data)
      quill.setSelection(length + data.length)
    },
    handleConfirm () {
      if (!this.form.name) {
        this.$message.warning('请输入模版名称')
        return
      }
      if (!this.form.content) {
        this.$message.warning('请输入模版内容')
        return
      }
      this.$emit('ok', this.form)
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange ({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.$emit('change', html)
    }
  },
  watch: {
    value (val) {
      console.log('我是监视器', val)
      this.content = val
    }
  }
}
</script>
<style>
.ql-container{
  height: 300px;
}
</style>
