<template>
  <a-modal
    :title="!this.model?'新建打款账户':'编辑打款账户'"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', this.selectCategory) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="银行账户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            :disabled="!!this.model"
            v-decorator="['account',
                          {rules: [
                            {required: true, message: '请输入'},
                            {min:8, max:32, message: '输入不符合长度限制'}
                          ]}]" />
        </a-form-item>
        <a-form-item label="开户行" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['account_bank', {rules: [{required: true, message: '请输入'}]}]" :disabled="!!this.model" />
        </a-form-item>
        <a-form-item label="公司名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['company_name', {rules: [{required: true, message: '请输入'}]}]" :disabled="!!this.model" />
        </a-form-item>
        <a-form-item label="分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// import pick from 'lodash.pick'
// 表单字段
const fields = ['account', 'account_bank', 'water_electric', 'company_name']

const plainOptions = ['水费', '电费']
const selectCategory = 0
const defaultCheckedList = ['']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      checkedList: defaultCheckedList,
      plainOptions,
      selectCategory,
      defaultCheckedList,
      dataSource: [],
      tip: '',
      corList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      allPerlist: [],
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
  },
  mounted () {
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      // this.model && this.form.setFieldsValue(pick(this.model, fields))
    })

    this.model && this.form.setFieldsValue({
      account: this.model.account,
      account_bank: this.model.account_bank,
      company_name: this.model.company_name
    })
    this.defaultCheckedList = []
    if (this.model !== null && this.model.water) {
      this.defaultCheckedList.push('水费')
    }

    if (this.model !== null && this.model.electric) {
      this.defaultCheckedList.push('电费')
    }
    this.checkedList = this.defaultCheckedList
  },
  methods: {
    handleRoleChange (value) {
      console.log(`selected ${value}`)
    },
    handlePermissionChange (value) {
      console.log(`selected ${value}`)
    },
    onChange (value) {
      // 都不选 0；选择一个 1或者2；选择两个 3
      if (value.length === 0) {
        this.selectCategory = 0
      } else if (value.length === 2) {
        this.selectCategory = 3
      } else {
        if (value[0] === '水费') {
          this.selectCategory = 1
        } else {
          this.selectCategory = 2
        }
      }
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
