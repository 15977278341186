<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAddBankAccount" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="contracts" slot-scope="contracts">
        <a-contract
          v-for="contract in contracts"
          :key="contract"
          :color="contract === 'loser' ? 'volcano' : contract.length > 5 ? 'geekblue' : 'green'"
        >
          {{ contract }}
        </a-contract>
      </span>
      <span slot="action" slot-scope="text, record" v-action:edit>
        <template>
          <a @click="handleRead(record)">编辑</a>
        </template>
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />

    <create-bank-account-form
      v-if="visibleBankAccount"
      ref="createBankAccountModal"
      :visible="visibleBankAccount"
      :loading="confirmBankAccountLoading"
      :model="mdl"
      @cancel="handleAddBankAccountCancel"
      @ok="handleBankAccountOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import CreateBankAccountForm from '@/views/a-bank-account/modules/CreateBankAccountForm'
import { bank_payment_account_create, bank_payment_account_list, bank_payment_account_update } from '@/api/bank_account'

export default {
  name: 'TableList',
  components: {
    CreateBankAccountForm,
    STable,
    CreateForm
  },
  data () {
    return {
      visibleBankAccount: false,
      confirmBankAccountLoading: false,
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '公司名称',
          dataIndex: 'company_name',
          ellipsis: true
        },
        {
          title: '银行账号',
          dataIndex: 'account',
          ellipsis: true
        },
        {
          title: '开户行',
          dataIndex: 'account_bank',
          ellipsis: true
        },
        {
          title: '水费',
          dataIndex: 'water',
          customRender: (text) => (text ? '是' : '否'),
          ellipsis: true
        },
        {
          title: '电费',
          dataIndex: 'electric',
          customRender: (text) => (text ? '是' : '否'),
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        // Object.assign(parameter, this.queryParam)
        return bank_payment_account_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAddCancel () {
      this.visible = false
    },
    handleRead (record) {
      console.log(record.id)
      this.mdl = record
      this.visibleBankAccount = true
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    handleAddBankAccount () {
      this.mdl = null
      this.visibleBankAccount = true
    },
    handleAddBankAccountCancel () {
      this.visibleBankAccount = false
      const form = this.$refs.createBankAccountModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleBankAccountOk (selectCategory) {
      this.visibleBankAccount = false
      const form = this.$refs.createBankAccountModal.form
      this.confirmBankAccountLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // console.log('values', values, selectCategory)
          const accountData = {}
          if (selectCategory === 1) {
            accountData.water = true
          } else if (selectCategory === 2) {
            accountData.electric = true
          } else if (selectCategory === 3) {
            accountData.water = true
            accountData.electric = true
          }

          if (this.mdl) {
            // 修改
            bank_payment_account_update(accountData, this.mdl.id).then(res => {
              this.visibleBankAccount = false
              this.confirmBankAccountLoading = false
              // 重置表单数据
              form.resetFields()
              // // 刷新表格
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmBankAccountLoading = false
              this.mdl = {}
            })
          } else {
            // 新增
            accountData.account = values.account
            accountData.account_bank = values.account_bank
            accountData.company_name = values.company_name
            bank_payment_account_create(accountData).then(res => {
              this.visibleBankAccount = false
              this.confirmBankAccountLoading = false
              // 重置表单数据
              form.resetFields()
              // // 刷新表格
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmBankAccountLoading = false
              this.mdl = {}
            })
          }
        } else {
          this.confirmBankAccountLoading = false
        }
      })
    }
  }
}
</script>
