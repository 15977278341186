<template>
  <div>
    <a-row>
      <a-col span="10">
        <a @click="handleGoBack">返回</a>
      </a-col>
      <a-col span="8"></a-col>
    </a-row>
    <div v-html="content">
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import { user_update } from '@/api/user'
import { contract_template_read } from '@/api/contract'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      content: null
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        user_update(values, this.id)
          .then((res) => {
            this.$notification.success({
              message: '修改成功',
              description: `修改成功`
            })
            console.log(res, '修改成功')
    })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      contract_template_read(data.id).then(({ data }) => {
       console.log(data, '我是模板')
        this.content = data.content
      })
    }
  }
}
</script>
