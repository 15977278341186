import request from '@/utils/request'

const bank_accountApi = {
  bank_payment_account_create: '/contract/payment_account/'
}

/**
 * 列表
 */
export function bank_payment_account_list (parameter) {
  return request({
    url: bank_accountApi.bank_payment_account_create,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 添加打款账户
 */
export function bank_payment_account_create (parameter) {
  return request({
    url: bank_accountApi.bank_payment_account_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 修改打款账户
 */
export function bank_payment_account_update (parameter, id) {
  return request({
    url: bank_accountApi.bank_payment_account_create + id,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
